
  import { Component, Vue } from 'vue-property-decorator';
  import axios from 'axios';
  import ArticleList from '@/components/articles/ArticleList.component.vue';
  import { Article } from '@/interfaces/arcticles/Article.entity';
  import { AuthenticationService } from '@/services/authentication.service';
  import ArticleDetails from '@/components/articles/ArticleDetails.component.vue';
  import ArticleEditor from '@/components/articles/ArticleEditor.component.vue';
  import * as UrlConsts from '@/scripts/UrlConsts';
  import * as Consts from '@/scripts/Consts';


/**
 * @desc page d'édition d'un article
 */
  @Component({
    components: {
      'article-details' : ArticleDetails,
      'article-editor' : ArticleEditor
    },
  })
  export default class ArticleEditionView extends Vue {
    article : Article = new Article();
    articleId = 0;
    classroomLoggedIn = false;
    queryType = '';

    mounted() {
      setTimeout(() => {
        this.getArticleId();
        this.queryType = this.getQuery();
      }, 200);
      if(AuthenticationService.getUserStatus() === Consts.user_status_classroom){
        this.classroomLoggedIn = true;
      }
    }

    /**
     * @desc récupère l'id de l'article à afficher
     */
    private getArticleId() : void {
      this.articleId = parseInt(this.$route.params.id);
      this.loadArticle(this.articleId);
    }

    /**
     * @desc récupère les données de la requète url, et le d'édition demandée
     */
    private getQuery() : string {
      const query = window.location.search.substr( window.location.search.indexOf('?') + 1);
      const urlSearchParams = new URLSearchParams(query);
      const params = Object.fromEntries(urlSearchParams.entries());
      if(params?.type)
        return params.type;

      return '';
    }

    /** 
     * @desc charge l'article indiqué
     */
    private loadArticle(articleId : number) {
      const headers = AuthenticationService.getRequestHeader();

      axios
        .get(UrlConsts.getArticle + '/' + articleId, headers)
        .then((response) => {
          this.article = new Article(response.data);
        })
        .catch(error => console.log(error))
    }
  }
